import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';

API.configure({
  endpoints: [
    {
      name: 'API',
      endpoint: `${process.env.REACT_APP_API_URL_BASE}`,
      custom_header: async () => {
        return {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        };
      },
    },
  ],
});

export type Tenant = {
  tenantId: string;
  name: string;
  tenantAdminEmail: string;
};

export type GetTenantsResponse = Tenant[];

export const getTenants = async (): Promise<GetTenantsResponse> => {
  const res = await API.get('API', '/tenants', {});
  return res as GetTenantsResponse;
};

export type PostTenantsRequest = {
  tenantAdminTemporaryPassword: string;
} & Tenant;

export type PostTenantsResponse = Tenant;

export const createTenant = async (props: PostTenantsRequest): Promise<PostTenantsResponse> => {
  await API.post('API', '/tenants', {
    body: props,
  });
  return {} as PostTenantsResponse;
};

export type FindTenantsRequest = {
  tenantId: string;
};

export type FindTenantsResponse = Tenant;

export const findTenant = async (props: FindTenantsRequest): Promise<FindTenantsResponse> => {
  const { tenantId } = props;
  const res = await API.get('API', `/tenants/${tenantId}`, {});
  return res as FindTenantsResponse;
};

export type UpdateTenantsRequest = Tenant;

export type UpdateTenantsResponse = Tenant;

export const updateTenant = async (props: UpdateTenantsRequest): Promise<UpdateTenantsResponse> => {
  const { tenantId, ...res } = props;
  await API.patch('API', `/tenants/${tenantId}`, {
    body: { ...res },
  });
  return {} as UpdateTenantsResponse;
};
