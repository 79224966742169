import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { createTenant, PostTenantsRequest } from '../../libs/api';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

type FormType = PostTenantsRequest;
const Page: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormType>({
    mode: 'onBlur',
  });

  const onSubmit = (data: FormType) =>
    createTenant(data)
      .then(() => {
        navigate('/tenants', { replace: true });
      })
      .catch((e) => {
        setError(e.message);
      });

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        Add new tenant
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Controller
            name="tenantId"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label="ID"
                {...field}
                helperText="Enter a statement that identifies the tenant. Used internally by the system. Cannot be changed. ex) abchotel"
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label="Name"
                {...field}
                helperText="Please enter the name of the tenant. e.g.) ABC Hotel"
              />
            )}
          />
          <Controller
            name="tenantAdminEmail"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label="TenantAdminEmail"
                helperText="Please enter the Email of this tenant administrator. Cannot be changed. Example: admin@example.com"
                {...field}
              />
            )}
          />
          <Controller
            name="tenantAdminTemporaryPassword"
            control={control}
            rules={{
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
                message: 'Between 8 and 99 alphanumeric symbols',
              },
            }}
            render={({ field }) => (
              <TextField
                // 文字列があった場合にはtrueとします。
                error={!!errors.tenantAdminTemporaryPassword}
                required
                type="password"
                helperText={
                  errors.tenantAdminTemporaryPassword?.message ||
                  'Enter the password for this tenant administrator first login. The tenant administrator will be prompted to change the password the first time he/she logs in.'
                }
                label="Temporary password for tenant administrator"
                {...field}
              />
            )}
          />
          <Stack direction="row">
            <Button type="submit" variant="contained" loading={isSubmitting}>
              Create
            </Button>
          </Stack>
        </Stack>
      </form>
      {error && (
        <Stack py={2}>
          <Alert severity="error">
            <AlertTitle>Server Error</AlertTitle>
            {error}
          </Alert>
        </Stack>
      )}
    </Box>
  );
};

export default Page;
