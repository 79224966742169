import { Amplify, Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import IndexPage from './pages';
import TenantIndexPage from './pages/tenants';
import TenantNewPage from './pages/tenants/new';
import TenantEditPage from './pages/tenants/edit';
import AdminIndexPage from './pages/admins';

import './App.css';

const config = {
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  },
};

Amplify.configure(config);
Auth.configure(config);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IndexPage isAdmin={true} />}>
          <Route path="/tenants" element={<TenantIndexPage />} />
          <Route path="/tenants/new" element={<TenantNewPage />} />
          <Route path="/tenants/:tenantId" element={<TenantEditPage />} />
          <Route path="/admins" element={<AdminIndexPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
