import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const Component: React.FC<{
  primary: string;
  to: string;
}> = (props) => {
  const { primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any>(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <ListItem button component={renderLink}>
      <ListItemText primary={primary} />
    </ListItem>
  );
};

export default Component;
